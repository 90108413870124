import React from "react"
import BusSideIcon from "mdi-react/BusSideIcon"

import HeartPulseIcon from "mdi-react/HeartPulseIcon"
  

import PageBody from "../../components/page-body/pageBody"
import SEO from "../../components/seo"

import Banner from "../../components/banner/banner"
import pastorMentalHealth from "../../images/components/pastor-mental-health.jpg"
import pastorMentalHealthBody1 from "../../images/components/pastor-mental-health-body-1.jpg"

import BorderTitle from "./../../components/border-title/borderTitle"
import ComponentNavigator from "./../../components/component-navigator/componentNavigator"
import QuoteImages from "./../../components/quote-images/quoteImages"

import "./pastor-mental-health-coach-training-program.scss"

var PastorMentalHealthCoachTrainingProgram = props => (
  <>
    <SEO title="Home" keywords={["gatsby", "application", "react"]} />
    <div className="pastor-mental-health-coach-training-program-page">
      <Banner
        src={pastorMentalHealth}
        title="Pastor Mental Health Coach Training Program"
        height="32.5rem"
        heightMobile="120vw"
        backgroundPosY="70%"
      />
      <PageBody className="page">
        <BorderTitle>
          Pastor Mental Health Coach Training Program
        </BorderTitle>
        <div className="body">
          <img className="left" src={pastorMentalHealthBody1} alt="" /><p>Twenty pastors from among the churches that have Health-Smart pantries or other pastors in Health Zone 1 are trained to be mental health coaches for their congregants and community members.</p>
        </div>
        
        <div className="navigation">
        <ComponentNavigator
            link="/components-year-two/the-health-smart-behavior-program"
            className="first"
            icon={HeartPulseIcon}
            title="The Health-Smart Behavior Program™ (called Health-Smart) "
            />
          <ComponentNavigator
            next
            link="/components-year-two/mobile-food-market-program"
            icon={BusSideIcon}
            title="Mobile Food Market Program"
          />
        </div>
      </PageBody>
    </div>
  </>
  )

export default PastorMentalHealthCoachTrainingProgram
